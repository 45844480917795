<template>
  <div class="cn-top">
    <header class="top-header">
      <div class="ob-logo">
        <a href="/" @click.prevent="handleClick">
          <img src="@/assets/images/logo.png" />
        </a>
      </div>
      <div class="lang-style">
        <slot></slot>
      </div>
      <div class="pc-top-navs">
        <template v-for="(nav, i) in NAVS">
          <div class="navs-box" :class="{'active': isActive(nav)}" :key="i">
            <a
                    href="javascript:;"
                    @click="routerTo(nav, 1)"
            >{{$t(nav.title)}}</a>
            <div class="dropdown" :key="'drop'+i">
              <ul>
                <li v-for="(drop_list, index) in nav.drop_list" :key="index" @click="route_link(drop_list.model_id, drop_list.h_key)">
                  {{drop_list.model_name}}
                  <!--<router-link :to="{name: '/doc', params: {m: drop_list.model_id}}">{{drop_list.model_name}}</router-link>-->
                  </li>
                <!--<li v-for="(drop_list, index) in $t(nav.drop_list)" :key="index" @click="route_link(drop_list.link)">{{drop_list.link_name}}</li>-->
              </ul>
            </div>
          </div>

        </template>
        <div :class="['transform-search', searchFlag ? 'searchform' : null]">
             <el-input ref="search" class="el-dropdown-link search-input" size="medium" v-model="search_value" @click.native.stop="clickInput" @input="globalSearch"></el-input>
             <i class="el-icon-search" @click.stop="clickInput('icon')"></i>
            <ul>
                <div class="search_down_menu" v-if="searchFlag && search_list.length > 0">
                    <li class="el-dropdown-menu__item" @click="searchSubmit({m: item.model_id, h: item.hash_key})" v-for="(item, k) in search_list" :key="k">
                        <div class="search_list_header">
                            <div class="item_titile" v-html="item.title"></div>
                            <div class="item_bre" v-html="item.path"></div>
                        </div>
                        <p class="search_list_text" v-html="item.content"></p>
                    </li>
                </div>
                <div  class="search_down_menu" v-if="searchFlag && search_list.length === 0 && isSearchNone">
                    <li class="el-dropdown-menu__item_none">
                        暂无数据
                    </li>
                </div>
            </ul>
        </div>
        <el-button class="apiConsole" type="primary" plain round size="medium" @click="toOB(1)">控制台</el-button>
      </div>
    </header>
  </div>
</template>
<script>
import i18n from '@/lang'
import urls from '@/container/urls'
const NAVS = [
  {
    title: 'navbar.dashboard',
    route_name: 'home',
    path: '/',
  },
  {
    title: 'navbar.open',
    route_name: 'open',
    path: '/open'
  },
  // {
  //   title: 'navbar.platform.title',
  //   route_name: 'platform',
  //   path: '/platform?m=19',
  //   // drop_list: 'navbar.platform.drop_list'
  // },
    {
        title: 'navbar.dev_doc',
        route_name: 'dev_doc',
        path: '/dev_doc?m=27',
    },
        {
        title: 'navbar.doc.title_en',
        route_name: 'dev_doc_en',
        path: '/dev_doc_en?m=28',
    },
    {
        title: 'navbar.doc.title',
        route_name: 'doc',
        path: '/doc',
        drop_list: [
            {model_id: 21,
            model_name:"Android原生开发文档(apk)",
            summary: "Android原生开发文档(apk)",
            h_key: 'c086fc5',
            network: 1},
            {model_id: 22,
            model_name:"机器人插件开发(opk)",
            summary: "机器人插件开发(opk)",
            h_key: 'b18e056',
            network: 1},
            {model_id: 30,
            model_name:"机器人插件开发(APK)",
            summary: "机器人插件开发(APK)",
            h_key: '381f6b6',
            network: 1},
            {model_id: 29,
            model_name:"服务端对接文档",
            summary: "服务端对接文档",
            h_key: '14ab598',
            network: 1},
            {model_id: 24,
                model_name:"运营平台",
                summary: "运营平台",
                h_key: '8d34eae',
                network: 1}
        ]
    },
    {
        title: 'navbar.help',
        route_name: 'help',
        path: '/help?m=20'
    },

  {
    title: 'navbar.example',
    route_name: 'example',
    path: '/example',
    drop_list: [
        {model_id: 25,
            model_name:"RobotApp示例",
            summary: "RobotApp示例",
            h_key: 'a38c5c6',
            network: 1},
        {model_id: 26,
            model_name:"AndroidApp示例",
            summary: "AndroidApp示例",
            h_key: '0f45d6e',
            network: 1},
    ]
  },
    // {
    //     title: 'navbar.doc.title',
    //     route_name: 'doc',
    //     path: '/doc',
    //     drop_list: 'navbar.doc.drop_list'
    // }
]
export default {
  props: {
    page: {
      type: String,
      default: ''
    },
    // navs: {
    //   type: Array,
    //   default: () => []
    // }
  },
  data() {
    return {
      NAVS,
      open: false,
      toggle_count: 0,
      searchFlag: false,
      // langSwitch: false,
      search_value: '',
      search_list: [],
      lang: '中文',
        isSearchNone:false,
    }
  },
  mounted() {
      document.addEventListener('click', () => { this.searchFlag = false })
      // this.getModels()
  },
  methods: {
      clickInput(type,e){
            if(type === 'icon'){
                this.$refs.search.focus()
            }
          this.searchFlag = true
      },
      async getModels() {
          let { header, data } = await this.$axios.get(urls.get_models)
          if (header.code == 0 && Array.isArray(data)&&data.length>0) {
              NAVS[3].drop_list = data
          }else{
              NAVS[3].drop_list = []
          }
      },

      async globalSearch () {
          if (!this.search_value) {
              return this.search_list = []
          }
          let params = {
              keyword: this.search_value,
          }
          const { header, data } = await this.$axios.get(urls.global_Search, params)
          if (header.code == 0 && data) {
              if(data.list.length>0){
                  this.search_list = data.list
              }

              if(data.list.length === 0){
                  this.isSearchNone = true
              }
          }
      },
      async searchSubmit(command) {
          let {m, h} = command
          let url = '/doc'
          // 平台接入，帮助支持，示例 跳转到对应的页面文档
          if (m == 19) {
              // 平台接入
              url = '/platform'
          } else if (m == 20) {
              // 帮助和支持
              url = '/help'
          } else if (m == 25 || m == 26) {
              // 25 示例- RobotApp示例
              // 26 示例- Androidapp示例
              url = '/example'
          }
          let path = `${url}?m=${m}&h=${h}&lang=cn`
          this.$router.push({path})
      },
      handleLang (command) {
          this.lang = command
          this.fn()
      },
    fn(){
      /*let type;
      if(this.$store.getters['app/language']=='en'){
        type = 'zh'
      }else{
        type = 'en'
      }
      this.$i18n.locale = type
      this.$store.dispatch('app/setLanguage', type)*/
      let type = 'zh';
      this.$i18n.locale = type
      this.$store.dispatch('app/setLanguage', type)
    },
    isActive(nav) {
      let name = this.$route.meta.name
      let navName = nav.route_name
      if (navName == name) return true
      return false
    },
    handleClick() {
      if (this.$route.path == '/') return;
      this.$utils.GATracking('返回首页', '点击', this.page)
      this.$router.push('/')
    },
    route_link (path, h_key) {
          if (path == 25 || path == 26) {
              this.$router.push(`/example?m=${path}&h=${h_key}`)
              return
          }
        this.$router.push(`/doc?m=${path}&h=${h_key}`)
    },
    routerTo(nav, type) {
      if (type == 2) {
        this.toggleMobileNavs()
      }
      let { path, route_name, title } = nav
      let name = this.$route.meta.name
      if (name == route_name) return
      this.$utils.GATracking(title, '点击', `${type == 1 ? 'PC' : 'Mobile'}-${this.page}`)
        if (path == '/doc') return
        if (path == '/example') return
        this.$router.push(path)
    },
    toOB(type) {
      this.$utils.GATracking('跳转控制台', '点击', `${type == 1 ? 'PC' : 'Mobile'}-${this.page}`)
      location.href = this.$envConfig.obUrl
    },
    toggleMobileNavs() {
      this.open = !this.open
      this.toggle_count++
    }
  }
}
</script>
<style lang="less">
@blue: #6976FF;
@black: #141419;
@keyframes change{
  from {width: 100px}
  to {width: 60%;height: 68px;}
}
.cn-top {
  background: #fff;
  box-sizing: border-box;
  height: 68px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}
.top-header {
  display: flex;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .lang-style{
      display: none;
  }
  .ob-logo {
    display: inline-block;
    img {
      display: block;
      width: 187px;
    }
  }
  .pc-top-navs {
    height: 100%;
    display: flex;
    align-items: center;
    .navs-box {
      display: flex;
      align-items: center;
      position: relative;
      height: 100%;
      margin-left: 35px;
      a {
        position: relative;
        font-size: 16px;
        color: #141419;
        text-decoration: none;
        cursor: pointer;
      }
      &::after {
        content: "";
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background: @blue;
        transform: scaleX(0);
        opacity: 0;
        transition: opacity .15s, transform .2s cubic-bezier(.20, .60, .23, .86);
      }
      &.active {
        a {
          color: @blue;
        }
        &::after {
          opacity: 1;
          transform: scaleX(1);
        }
      }
      &:hover {
        text-decoration: none;
        a {
          color: @blue;
        }
        &::after {
          opacity: 1;
          transform: scaleX(1);
        }
        .dropdown {
          transition: .3s;
          opacity: 1;
          display: block;
        }
      }
      .dropdown {
        position: absolute;
        top: 68px;
        background: rgba(252, 252, 253, 1);
        opacity: 0;
        display: none;
        ul {
          list-style: none;
          padding: 0;
          border: 1px solid #e4e7ed;
          border-radius: 4px;
          li {
            height: 40px;
            line-height: 40px;
            padding: 0 30px;
            font-size: 14px;
            color: #606266;
            white-space: nowrap;
            cursor: pointer;
            &:hover {
              color: @blue;
              background: #f5f7fa;
            }
          }
        }
      }
    }
    .el-dropdown-link {
      cursor: pointer;
      color: #409EFF;
      background: #fff;
    }
      .el-dropdown-link .el-input__inner {
          padding-right: 26px;
      }
    .el-icon-arrow-down {
      font-size: 12px;
    }
    .transform-search {
      width: 100px;
      height: 68px;
      line-height: 68px;
      position: absolute;
      right: 120px;
      transition:width .3s;
      .el-input__inner {
        border-radius: 18px;
        padding-left: 20px;
        box-shadow: none;
      }
      .el-icon-search {
        font-size: 14px;
        color: #606266;
          cursor: pointer;
        position: absolute;
          right: 8px;
          top: 28px;
      }
    }
    .searchform {
      transition: width 0.5s;
      width: 60%;
      /*background: #f4f5f6;*/
    }
    .apiConsole {
      margin: 0 20px 0 140px;
    }
  }
  .top-btn {
    display: flex;
    box-sizing: border-box;
    height: 38px;
    padding: 10px 30px;
    font-size: 18px;
    align-items: center;
    color: #1B1A3A;
    border: 1px solid #1B1A3A;
    border-radius: 19px;
    cursor: pointer;
    margin-left: 48px;
  }
}
  .search_down_menu{
    width: 100%;
    display: block;
      background-color: #FFF;
      border: 1px solid #EBEEF5;
      border-radius: 4px;
      box-shadow: 0 2px 12px 0 rgba(0 0 0 0.1);
    left: calc((100% - 490px)/2)!important;
    top: 50px!important;
      .el-dropdown-menu__item_none{
          list-style: none;
          padding: 6px 20px;
      }
    .el-dropdown-menu__item {
      padding: 6px 20px;
      &:hover {
        background: #FAFAFA;
      }
      .search_list_header {
        display: flex;
        height: 20px;
        line-height: 20px;
        .item_titile {
          font-size: 14px;
          color: #444;
          padding-right: 10px;
        }
        .item_bre {
          font-size: 12px;
          color: #999;
        }
      }
      .search_list_text {
        height: 18px;
        line-height: 18px;
        font-size: 12px;
        color: #777;
        overflow:hidden; /*超出的部分隐藏起来。*/
        white-space:nowrap;/*不显示的地方用省略号...代替*/
        text-overflow:ellipsis;/* 支持 IE */
      }
    }
  }
.none_down_menu.el-dropdown-menu.el-popper {
  display: none;
}
</style>
