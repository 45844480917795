export default {
  login: '/cn/login',
  create_model: '/cn/create_model', // 创建模块
  get_models: '/cn/get_models', // 获取文档模块
  update_model: '/cn/update_model', // 跟新文档模块
  get_menus: '/cn/get_menus', // 获取文档模块的菜单
  get_docs: '/cn/get_docs', // 获取文档内容
  create_docs: '/cn/create_docs', // 创建文档
  update_docs: '/cn/update_docs', // 更新文档
  del_doc: '/cn/del', // 删除文档
  publish: '/cn/update_online', // 同步到线上
  check_net_type:'/cn/check/net',
  global_Search:'/dev_doc/search', // 头部搜索
}